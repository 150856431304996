.zchat-msg-htmltag-url{
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  color: blue;
}

.zchat-msg-htmltag-mail{
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  color: blue;
}

.zchat-msg-htmltag-video{
  min-width: 250px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
  /* IE10+ specific styles go here */  
  
}