.Dropzone {
  height: 100%;
  width: 100%;
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  em {
    font-style: italic;
  }

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    &.over {
      opacity: 0.8;
      border-color: blue;
    }
  }
}
