@media only screen and (max-width: 768px) {
  .zchat.quickreplybar-btn-text.safari {
    font-size: 16px;
  }

  .zchat {
    font-size: calc(16px + 0.1vw);

    .msg-footer-lapse {
      font-size: 10px;
    }

    .msg-footer-datetime {
      font-size: 10px;
    }
  }

  .ztablizar {
    max-width: 99%;
  }

  .zchat.msg-text {
    overflow-wrap: break-word;
    font-size: calc(16px + 0.1vw) !important;
    white-space: pre-wrap;
  }

  .zchat.msg-footer {
    font-size: xx-small;
  }

  .zchat.send {
    color: rgb(0, 137, 99);
    margin: 5px 5px;
    padding: 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
  }

  .zchat.input.safari {
    height: 30px;
    height: calc(30px + var(--vh, 1vh) * 1);
  }

  .zchat.input {
    position: relative;
    bottom: 0;
    padding: 2px;
    padding-bottom: 5px;
    left: 0;
    width: 100%;
    display: flex;
    margin-right: "5px";
    background-color: white;
  }
  .TestChat {
    overflow: visible !important;
    width: 100vw;
    margin-bottom: 60px;
    background-color: #f0f8ff;
    background-color: #0000;
    height: auto !important;
    .centre {
      min-height: 0 !important;
    }
    .TabHeader {
      position: fixed;
      top: 0;
      height: 50px;
    }
    .TabContent {
      padding-top: 50px !important;
    }
    .zchat.msglist {
      overflow: visible;
      min-height: calc(100vh);
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
.headline {
  user-select: none;
  box-sizing: border-box;
  max-width: 600px;
  min-height: 50px;
  height: 60px;
  border-radius: 0;
  background: #005f73;
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 5px;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    color: white;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    text-align: center;
    position: relative;

    .syva_logo {
      mask: url(./logo_syva_black.svg) no-repeat center/contain;
      width: 200px;
      height: 200px;
      margin-right: 10px;
      opacity: 0.2;
      background-color: #fff;
      position: relative;
      z-index: 1;
    }
    .modelName {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 2;
      color: white;
      font-size: normal normal normal 12px/16px Segoe UI;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: right;
    text-align: right;
    color: white;
    float: right;
    position: relative;
    //width: 70px;
    display: flex;
    margin-right: 40px;

    .text {
      padding-bottom: 3px;
      white-space: nowrap;
      display: flex;
      align-items: right;
      text-align: right;
      flex-grow: 1;
      text-align: right;
      font: normal normal bold 20px/27px Segoe UI;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .time {
      text-align: right;
      font: normal normal normal 12px/16px Segoe UI;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    .headline {
      display: block;
    }

    .icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 10px 5px;
    }

    .text {
      align-items: center;
      display: inline;
      position: relative;
      padding: 0px;
    }

    .left {
      display: inline-block;
      height: 50px;
    }

    .right {
      display: inline-block;
      height: 45px;
    }
  }
}
