.LCA {
  .hide {
    display: none;
  }
  .MuiTab-textColorInherit {
    color: #257caf;
  }
  .MuiTabs-indicator {
    background-color: #257caf;
  }
}
