.container {
  display: flex;
  padding-top: 34px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .Spacer {
    height: 10%;
  }
}

.scoreCards {
  height: 100%;
  overflow: revert;
  justify-content: space-between;
  align-items: space-between;
  display: flex;
  width: 100%;
}
