.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container {
  width: 100%;
  height: 70%;
  display: flex;

  .recharts-legend-item {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .layout {
    margin-bottom: 0;
  }
}
