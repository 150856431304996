.speech-container{
  
}

.speech-container.show{
  transform: scale(1);
  transition: transform 0.2s;
}

.speech-container.hide{
  transform: scale(0);
  transition: transform 0s;
}

.speech-bubble {
  position: relative;
  margin: .5em auto;
  padding: 15px;
  border-radius: .25em;
  height: 4em;
  width: 10em;
  transform: rotate(-4deg) rotateY(15deg);
  background: #629bdd;
  font: 2em/4 Century Gothic, Verdana, sans-serif;
  text-align: center;
  transition: width 0.5s, height 0.5s;
}

.speech-bubble:before, .speech-bubble:after {
  position: absolute;
  z-index: -1;
  content: '';
}
.speech-bubble:after {
  top: 0; right: 0; bottom: 0; left: 0;
  border-radius: inherit;
  transform: rotate(2deg) translate(.35em, -.15em) scale(1.02);
  background: #e6f7ff;
}
.speech-bubble:before {
  border: solid 0 transparent;
  border-right: solid 3.5em #e6f7ff;
  border-bottom: solid .25em #629bdd;
  bottom: .25em; left: 2em;
  width: 0; height: 1em;
  transform: rotate(30deg) skewX(75deg);
}